import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/utils/Seo'
import TileSlider from '../components/pages/Tile'

export const TILE_QUERY = graphql`
  query TileQuery($prevSlug: String!, $slug: String!, $nextSlug: String!) {
    prevTile: wpTile(slug: { eq: $prevSlug }) {
      ...tileFragment
    }
    tile: wpTile(slug: { eq: $slug }) {
      ...tileFragment
    }
    nextTile: wpTile(slug: { eq: $nextSlug }) {
      ...tileFragment
    }
  }
`

export const tilesFragment = graphql`
  fragment tileFragment on WpTile {
    title
    id
    featuredImage {
      node {
        localFile {
          id
          childImageSharp {
            # fluid(maxWidth: 1400, quality: 70) {
            #   ...GatsbyImageSharpFluid_withWebp
            # }
            gatsbyImageData(
              width: 1400
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    grid {
      ...gridFragment
    }
  }
`

export const allTilesFragment = graphql`
  fragment allTilesFragment on WpTileConnection {
    totalCount
    edges {
      node {
        slug
        title
        id
        featuredImage {
          node {
            localFile {
              id
              childImageSharp {
                # fluid(maxWidth: 1400, quality: 70) {
                #   ...GatsbyImageSharpFluid_withWebp
                # }
                gatsbyImageData(
                  width: 1400
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        grid {
          ...gridFragment
        }
      }
    }
  }
`

const TileTemplate = (props) => {
  return (
    <>
      <Seo title={props.data.tile.title} {...props.international} />
      <TileSlider {...props} />
    </>
  )
}

export default TileTemplate
